import PropTypes from 'prop-types';

export default function Slide({className, ...props}) {
  return (
    <div className="mx-1.5 box-content overflow-hidden rounded-[20px] 3xl:mx-0">
      <div className={`!block w-full ${className}`} {...props} />
    </div>
  );
}
Slide.propTypes = {
  className: PropTypes.string,
};
Slide.defaultProps = {
  className: '',
};

export const PlasmicData = {
  name: 'HeroSlider.Slide',
  props: {
    className: 'string',
    children: {
      type: 'slot',
    },
  },
  importPath: './components/UI/Molecules/HeroSlider/Slide',
  isDefaultExport: true,
};
